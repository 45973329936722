import './App.css';
import Footer from './components/footer/footer.component';
import Header from './components/header/header.component';
import Main from './components/mainbody/mainbody.component';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import DoNotSell from './components/doNotSell/DoNotSell';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/terms/TermsOfUse';
import Disclaimer from './components/disclaimer/Disclaimer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
       <Router>
       <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/do-not-sell" element={<DoNotSell />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </Router>
    </div>
  );
}

export default App;
