import React, { useEffect } from 'react';
import './TermsOfUse.css'

const TermsOfUse = () => {
  useEffect(() => {
    // Load Termageddon script
    const script = document.createElement('script');
    script.src = 'https://app.termageddon.com/js/termageddon-init-compatibility.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup script when component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div
      id="policy_embed_div"
      width="640"
      height="480"
      data-policy-key="Y0RoNlRXdzRia2hqT0VsWVRrRTlQUT09"
      data-extra="css-compatibility=true&h-align=left&table-style=accordion"
    >
      Please wait while the policy is loaded. If it does not load, please{' '}
      <a
        rel="nofollow noreferrer"
        href="https://app.termageddon.com/api/policy/Y0RoNlRXdzRia2hqT0VsWVRrRTlQUT09?css-compatibility=true&h-align=left&table-style=accordion"
        target="_blank"
      >
        click here
      </a>
      .
    </div>
  );
};

export default TermsOfUse;
