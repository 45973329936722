import React from 'react';
import './Disclaimer.css'

const Disclaimer = () => {
  return (
    <div>
      <div
        id="policy_embed_div"
        width="640"
        height="480"
        data-policy-key="U2twT2VIUmlPVUZLUVZwc2VGRTlQUT09"
        data-extra="css-compatibility=true&h-align=left&table-style=accordion"
      >
        Please wait while the policy is loaded. If it does not load, please{' '}
        <a
          rel="nofollow noreferrer"
          href="https://app.termageddon.com/api/policy/U2twT2VIUmlPVUZLUVZwc2VGRTlQUT09?css-compatibility=true&h-align=left&table-style=accordion"
          target="_blank"
        >
          click here
        </a>
        .
      </div>
      <script src="https://app.termageddon.com/js/termageddon-init-compatibility.js"></script>
    </div>
  );
};

export default Disclaimer;
