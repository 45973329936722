import React from 'react'
// import { Divider } from '@material-ui/core'
import OtherBlogs from '../otherBlogs/OtherBlogs.jsx';
import './subfooter.style.css';

export default function Subfooter() {
    return (
        <section className="sub-footer">
            <div className="subf_container">
                <p>You may also like</p>
                <OtherBlogs />
            </div>
        </section>
    )
}