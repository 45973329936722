import React from 'react'
import './header.style.css'
import logo from '../../SeniorFinancialGuide_Logo.png'

export default function Header() {
    return (
        <header className="header">
            <div className="sec_container">
                <div className="top_small_txt">Advertisement</div>
                <div className="logo">
                    <a href="/"><img src={logo} alt="seniorfinancialguide" /></a>
                </div>
            </div>
        </header>
    )
}
