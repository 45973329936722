import React from 'react';
import { Divider } from '@material-ui/core';
// import StarRating from '../star-rating/starrating.component';
import './offerpost.style.css';

const OfferCard = ({ index, ...i }) => {
  const hideButton = !i.btnTitle;

  // Function to handle image source
  const getImageSource = () => {
    if (i.image.startsWith('http')) {
      // If img starts with 'http', assume it's a remote image
      return i.image;
    } else if (i.image.endsWith('.gif')) {
      // If img ends with '.gif', assume it's a local image in the public folder
      return `${process.env.PUBLIC_URL}${i.image}`;
    } else {
      // Assume it's a regular local image
      return `${process.env.PUBLIC_URL}/images/${i.image}`;
    }
  };

  return (
    <>
      <div className="offer_post">
        <h2>
          <a className='title__link' href={i.url}>
            <span className="unit">{i.count}</span>
            {i.title}
          </a>
        </h2>
        <div className="offer_image">
          {/* Call the getImageSource function */}
          <img src={getImageSource()} alt={i.title} />
        </div>
        <p className="offer_content" dangerouslySetInnerHTML={{ __html: i.description }} />
        {/* <StarRating rating={i.rating} /> */}
        <br />
        <a href={i.url} className={hideButton ? 'hideButton' : "offer_link button"} target="_blank" rel="noreferrer">
          {i.btnTitle}
        </a>
      </div>
      <Divider />
    </>
  );
};

export default OfferCard;
