import React, { useEffect, useState, useMemo } from 'react';
import './OtherBlogs.css';

const OtherBlogs = () => {
  const blogsData = useMemo(() => [
    {
      id: 1,
      title: 'This Brand Has Seniors Walking Again - Limited Time Offer',
      image: '../assets/compressa.png',
      url: 'https://www.p9v2ntrk.com/28KL6/23JF6C/',
      category: 'Health And Wellness'
    },
    {
      id: 2,
      title: 'Razor Sharp Damascus Steel Blade Knives Knockout Japanese Knives',
      image: '../assets/damaknives2.png',
      url: 'https://example.com/blog2',
      category: 'Damascus Knives'
    },
    {
      id: 3,
      title: 'Watch This Vacuum Destroying a Water Jug. Top Choice of 2024',
      image: '../assets/waterjug.gif',
      url: 'https://www.p9v2ntrk.com/63CFP/KMKS9/',
      category: 'EZVac Pro'
    },
    {
      id: 4,
      title: 'This Light Bulb Turned into a Secret Security Camera',
      image: '../assets/lightsocketsecurity.gif',
      url: 'https://www.p9v2ntrk.com/63CFP/PS824/',
      category: 'Light Socket Security Cam'
    },
    {
      id: 5,
      title: 'Stop Pain Where it Hurts. One Device relieves Neck, Waist, Hips…',
      image: '../assets/electrapyshop.png',
      url: 'https://www.p9v2ntrk.com/28KL6/XCQZJ/',
      category: 'Electrapy'
    },
    {
      id: 6,
      title: 'Body Aches and Pains Relieved With Japanese Technique',
      image: '../assets/hero_img006.jpg',
      url: 'https://www.p9v2ntrk.com/28KL6/225JFQ/',
      category: 'Rest-Nex'
    },
    {
      id: 7,
      title: '7 Huge Savings Most Seniors Missed in 2021',
      image: '../assets/seniors_unlocking_1222.jpg',
      url: 'https://example.com/blog1',
      category: 'health and wellness'
    },
    {
      id: 8,
      title: '10 Amazing Senior Savings and Services to Help You Live Independently',
      image: '../assets/hero_img002.jpg',
      url: 'https://example.com/blog2',
      category: 'Turbo Debt'
    },
    {
      id: 9,
      title: 'Money Savings and Senior Safety Discounts Top 10',
      image: '../assets/hero_img003.jpg',
      url: 'https://example.com/blog3',
      category: 'Nutrition And Healing'
    },
    {
      id: 10,
      title: 'Top Senior Savings & Services By Type',
      image: '../assets/hero_img004.jpg',
      url: 'https://example.com/blog4',
      category: 'health and wellness'
    },
    {
      id: 11,
      title: 'Seniors Treat Themselves with These Exclusive Offers',
      image: '../assets/hero_img005.jpg',
      url: 'https://example.com/blog5',
      category: 'Nutrition And Healing'
    },
    {
      id: 12,
      title: 'Senior Home Owners Guide to Resources',
      image: '../assets/hero_img006.jpg',
      url: 'https://example.com/blog6',
      category: 'Safe Cam 360'
    },
  ], []);

  const location = window.location;
  const [urlsWithParams, setUrlsWithParams] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Iterate through each blog and update the URL
    const updatedUrlsWithParams = blogsData.map(blog => {
      const urlWithParams = `${blog.url}?${searchParams.toString()}`;
      return urlWithParams;
    });

    // Optionally, you can log the updated URLs to verify
    console.log('Updated URLs with Params:', updatedUrlsWithParams);

    // Set the updated URLs to state
    setUrlsWithParams(updatedUrlsWithParams);
  }, [blogsData, location.search]);

  return (
    <div className="other-blogs">
      {blogsData.map((blog, index) => (
        <div key={blog.id} className="blog-item">
          <a href={urlsWithParams[index]} target="_blank" rel="noopener noreferrer">
            <img src={blog.image} alt={blog.title} />
            <p>{blog.title}</p>
            <p className='category'>{blog.category}</p>
          </a>
        </div>
      ))}
    </div>
  );
};

export default OtherBlogs;