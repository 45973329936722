// PrivacyPolicy.js
import React, { useEffect } from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  useEffect(() => {
    // Load Termageddon script on component mount
    const script = document.createElement('script');
    script.src = 'https://app.termageddon.com/js/termageddon-init-compatibility.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="policy_embed_div" width="640" height="480" data-policy-key="ZWl0M2FIWkpUblZJYjA1WGMxRTlQUT09" data-extra="css-compatibility=true&h-align=left&table-style=accordion">
      Please wait while the policy is loaded. If it does not load, please{' '}
      <a rel="nofollow noreferrer" href="https://app.termageddon.com/api/policy/ZWl0M2FIWkpUblZJYjA1WGMxRTlQUT09?css-compatibility=true&h-align=left&table-style=accordion" target="_blank">
        click here
      </a>.
    </div>
  );
};

export default PrivacyPolicy;
